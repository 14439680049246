<template>
  <v-card
    :href="link"
    @click.prevent="handleLink"
    flat
    class="proposal-img"
    rounded="md"
  >
    <v-img
      :src="src"
      :alt="proposal.name"
      :contain="contain"
      :class="{ fullsize: !contain }"
    >
      <div
        v-if="mode == 'overlay'"
        v-html="proposal.content"
        class="overlay-text"
      ></div>
    </v-img>
    <v-card-title v-if="mode == 'card'" class="justify-center font-weight-bold">
      {{ proposal.name }}
    </v-card-title>
    <v-card-text
      v-if="mode == 'card' && proposal.content"
      class="card-text justify-center text-center"
      v-html="proposal.content"
    >
    </v-card-text>
  </v-card>
</template>
<style lang="scss">
.overlay-text {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    text-align: center;
    margin-top: 10px;
    h1,
    span {
      font-size: 20px !important;
      margin-top: 0px;
      @media (max-width: 320px) {
        margin-top: -5px;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    position: absolute;
    top: 50%;
    text-align: right;
    right: 55%;
    transform: translate(0, -50%);
    line-height: 1.15em;
  }
}
.fullsize {
  .v-image__image {
    background-size: 100% !important;
  }
}
</style>
<script>
import banner from "~/mixins/banner";
import get from "lodash/get";

export default {
  name: "ProposalImage",
  props: {
    proposal: { type: Object, required: true },
    mode: { type: String, default: "banner" },
    contain: { type: Boolean, default: true }
  },
  mixins: [banner],
  computed: {
    src() {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
