<template>
  <div class="product-slider" v-if="products && products.length > 0">
    <h2
      class="text-center primary--text text--lighten-1 mt-4 mb-10"
      v-if="title"
      v-html="title"
    ></h2>
    <div class="product-list-swiper-container product-card-horizontal-mobile">
      <div
        v-if="showArrows && products.length > 1"
        :id="`product-list-slider-prev-${paginationClass}`"
        class="swiper-button-prev"
      />
      <swiper
        :options="swiperOption"
        ref="swiperRef"
        v-if="products.length > 0"
      >
        <swiper-slide v-if="hasImage">
          <ProposalImage :proposal="proposal" />
        </swiper-slide>
        <swiper-slide v-for="product in products" :key="product.productId">
          <ProductCard v-bind:product="product" />
        </swiper-slide>
      </swiper>
      <div
        v-if="showArrows && products.length > 1"
        :id="`product-list-slider-next-${paginationClass}`"
        class="swiper-button-next"
      />
    </div>

    <div
      v-if="products.length > 1"
      class="swiper-pagination"
      :class="paginationClass"
      slot="pagination"
    />
  </div>
</template>
<style scoped lang="scss">
.product-list-swiper-container {
  position: relative;
}
.swiper-container {
  padding-bottom: 12px;
}
</style>
<script>
import ProductService from "~/service/productService";
import ProductCard from "@/components/product/ProductCard";
import ProposalImage from "@/components/proposal/ProposalImage";
import get from "lodash/get";

export default {
  name: "ProductListSlider",
  props: {
    productList: { type: Array, required: false },
    categoryId: { type: Number, required: false },
    productId: { type: Number, required: false },
    warehouseId: { type: Number, required: false },
    layout: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    promo: { type: Boolean, required: false, default: false },
    limit: { type: Number, required: false, default: 8 },
    title: { type: String, required: false },
    showArrows: { type: Boolean, default: false },
    proposal: { type: Object, required: false },
    paginationClass: { type: String, required: false },
    fetchByCategory: { type: Boolean, default: false },
    cols: { default: 1 },
    sm: { default: 2 },
    md: { default: 3 },
    lg: { default: 5 },
    xl: { default: 6 }
  },
  components: { ProductCard, ProposalImage },
  data() {
    return {
      products: [],
      swiperOption: {
        slidesPerGroup: 1,
        spaceBetween: 24,
        watchOverflow: true,
        pagination: {
          el: "." + this.paginationClass,
          clickable: true
        },
        navigation: {
          prevEl: `#product-list-slider-prev-${this.paginationClass}`,
          nextEl: `#product-list-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          0: {
            slidesPerView: this.cols
          },
          600: {
            slidesPerView: this.sm
          },
          960: {
            slidesPerView: this.md
          },
          1280: {
            slidesPerView: this.lg
          },
          1590: {
            slidesPerView: this.xl
          }
        }
      }
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    if (this.mode == "card") {
      this.fetchProducts();
    }
  },
  computed: {
    hasImage() {
      return (
        this.proposal &&
        this.proposal.img &&
        this.proposal.img != "https://laspesaonline.eurospin.it"
      );
    },
    mode() {
      return get(this.proposal, "metaData.category_proposal_type.MODE", "card");
    }
  },
  methods: {
    async fetchProducts() {
      this.products = [];
      if (this.fetchByCategory) {
        let res = await ProductService.search({
          parent_category_id: this.categoryId,
          promo: this.promo,
          page_size: this.limit,
          sort: this.sort
        });
        this.products = res.products;
      } else if (this.productList) {
        this.products = this.productList;
      } else if (this.proposal) {
        let response = await ProductService.search({
          parent_category_id: this.proposal.categoryProposedId,
          page_size: this.limit,
          sort: this.sort
        });
        this.products = response.products;
        this.showMore = response.page.totItems > response.page.itemsPerPage;
      } else {
        let response = await ProductService.adv({
          layout: this.layout,
          product_id: this.productId,
          category_id: this.categoryId,
          warehouse_id: this.warehouseId,
          limit: this.limit,
          shuffle: this.shuffle,
          promo: this.promo
        });
        if (response) {
          this.products = response.products;
        }
        this.$emit("loaded", this.products ? this.products.length : 0);
      }
    }
  }
};
</script>
