<template>
  <v-container>
    <h2 class="text-center primary--text text--lighten-1 font-weight-bold mb-4">
      Error 404 - Pagina non trovata
    </h2>
    <v-card @click.prevent="clicked" flat class="">
      <v-row no-gutters class="proposal-card-vertical-responsive-row">
        <v-col cols="12" sm="7" class="">
          <div
            class="d-flex flex-column justify-center align-center content-column h-100 primary--text text--lighten-1 font-weight-bold"
          >
            <v-card-title class="justify-center text-h3 font-weight-bold"
              >Ops!</v-card-title
            >
            <v-card-text
              class="text-center primary--text  text-h5 text--lighten-1 font-weight-bold"
            >
              La pagina che stavi cercando non esiste.
            </v-card-text>
            <v-card-actions class="justify-center">
              <!-- class="justify-end justify-sm-start" -->
              <v-btn
                @click.prevent="clicked"
                depressed
                large
                :href="home"
                color="primary lighten-1"
              >
                torna a riempire il frigo
              </v-btn>
            </v-card-actions>
          </div>
        </v-col>
        <v-col cols="12" sm="5" class="img-column">
          <v-img
            class="image-card"
            src="/img_layout/frigo.png"
            alt="Torna a riempire il frigo"
            title=""
            contain
            max-height="250"
          />
        </v-col>
      </v-row>
    </v-card>

    <div class="mt-5 mb-8">
      <ProductListSlider
        :layout="25"
        :categoryId="40310"
        :fetchByCategory="true"
        :promo="true"
        :title="'Prodotti in promozione'"
        :paginationClass="'swiper-pagination-promo'"
        :showArrows="$vuetify.breakpoint.smAndUp"
      />
    </div>
    <!-- :href="" -->
    <v-row>
      <v-col cols="12" sm="6">
        <v-card rounded="lg" flat @click.prevent="clicked" class="">
          <v-row no-gutters class="proposal-card-vertical-responsive-row">
            <v-col
              cols="12"
              sm="6"
              class="img-column primary d-flex align-center"
            >
              <v-img
                class="image-card "
                src="/img_layout/faq.png"
                alt="faq"
                title="faq"
                contain
              />
            </v-col>
            <v-col cols="12" sm="6" class="d-flex flex-column content-column">
              <v-card-text
                class="d-flex flex-column justify-center align-center h-100"
                >Dai un'occhiata alle informazioni e alle curiosità più
                frequenti su Eurospin La Spesa Online
              </v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions class="justify-center">
                <v-btn
                  :href="faq"
                  @click.prevent="clicked"
                  depressed
                  large
                  color="secondary"
                >
                  scopri di più
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card rounded="lg" flat @click.prevent="clicked" class="">
          <v-row no-gutters class="proposal-card-vertical-responsive-row">
            <v-col
              cols="12"
              sm="6"
              class="img-column  primary d-flex align-center"
            >
              <v-img
                class="image-card"
                src="/img_layout/contatti.png"
                alt="contatti"
                title="contatti"
                contain
              />
            </v-col>
            <v-col cols="12" sm="6" class="d-flex flex-column content-column">
              <v-card-text
                class="d-flex flex-column justify-center align-center h-100"
                >Se hai dubbi o ti servono chiarimenti, non esitare a
                contattarci. Siamo a tua disposizione.
              </v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions class="justify-center yellow">
                <v-btn
                  :href="contatti"
                  @click.prevent="clicked"
                  depressed
                  large
                  color="secondary"
                >
                  scopri di più
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import clickHandler from "~/mixins/clickHandler";
import ProductListSlider from "@/components/product/ProductListSlider";
import ProductService from "~/service/productService";
export default {
  name: "Not-Found",
  mixins: [clickHandler],
  data() {
    return {
      product: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      showNoProductCard: false,
      selectedImage: null,
      key: 1
    };
  },
  computed: {
    faq() {
      return `${process.env.VUE_APP_EBSN_URL}faq/`;
    },
    home() {
      return `${process.env.VUE_APP_EBSN_URL}`;
    },
    contatti() {
      return `${process.env.VUE_APP_EBSN_URL}contatti/`;
    }
  },
  components: {
    ProductListSlider
  },
  methods: {
    async getProduct() {
      var data = await ProductService.getProductBySlug(this.slug);
      if (data) {
        this.product = data;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }

        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
      } else {
        this.showNoProductCard = true;
      }
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    },
    async reload() {
      await this.resetFilters(this.category.slug);
      await this.getProduct();
      this.key += 1;
    }
  }
  // created() {
  //   this.slug = this.$route.params.slug;
  //   this.showNoProductCard = false;
  //   this.getProduct();
  // },
};
</script>
